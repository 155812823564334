<template>
  <DataTable ref="dt" :value="summaries" class="p-datatable-sm" :loading="loading"
             scrollable row-hover scroll-height="400px">
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <ColumnGroup type="header">
      <Row>
        <Column field="year" header="Année" :rowspan="2" style="min-width: 100px"/>
        <Column field="month" header="Mois" :rowspan="2" style="min-width: 100px"/>
        <Column field="paidHT" header="Facture HT" :colspan="2" header-class="column-text-center" style="min-width: 100px"/>
        <Column header="Taux de gestion moyen" :colspan="2" class="column-text-center" style="min-width: 150px"/>
        <Column field="globalCompanyCost" header="Coût global salaire" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
        <Column field="ndfAmountRefunded" header="NDF / NDFR / Dispositifs sociaux / Achats fournisseur" :colspan="2" class="column-text-center" style="min-width: 200px"/>
        <Column field="reserve" header="Réserves bloquées" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
        <Column field="regularisation" header="Autres Régularisations" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
        <Column field="treasury" header="Trésorerie" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
        <Column field="waitingTreasury" header="En attente de paiement" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
        <Column field="pot" header="Cagnotte" :rowspan="2" class="column-text-right" style="min-width: 100px"/>
      </Row>
      <Row>
        <Column field="invoicedHt" header="Facturé" header-class="column-text-right"/>
        <Column field="waitingToPaidHt" header="En attente" header-class="column-text-right"/>
        <Column field="managementFee" header="%" header-class="column-text-right"/>
        <Column field="managementFeeAmount" header="Montant(€)" header-class="column-text-right"/>
        <Column field="ndfAmountRefunded" header="Remboursé" header-class="column-text-right"/>
        <Column field="ndfAmountWaiting" header="En attente" header-class="column-text-right"/>
      </Row>
    </ColumnGroup>
    <Column field="year"/>
    <Column field="month">
      <template #body="{data}">
         {{ mois[data.month - 1] }}
      </template>
    </Column>
    <Column field="invoicedHt" bodyClass="text-right">
      <template #body="{data}">
         {{ formatAmount(data.invoicedHt) }}
      </template>
    </Column>
<!--      :style="data.waitingToPaidHt ? 'background-color: #DD901DFF; color: white' : ''"-->
<!--      style="text-align: end"-->
    <Column body-class="text-right font-bold" field="waitingToPaidHt" body-style="color: #DD901DFF">
      <template #body="{data}">
        {{ formatAmount(data.waitingToPaidHt) }}
<!--          <Tag v-if="data.waitingToPaidHt" style="background-color: #DD901DFF; color: white" rounded :value="getEuroFormat(data.waitingToPaidHt)"></Tag>-->
<!--          <Chip style="background-color: #DD901DFF; color: white" class="" :label="data.waitingToPaidHt ? getEuroFormat(data.waitingToPaidHt) : ''"/>-->
      </template>
    </Column>
    <Column bodyClass="text-right" field="managementFeeAverage">
      <template #body="{data}">
         {{ data.managementFeeAverage ? `${data.managementFeeAverage.toFixed(1)} %` : '' }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="managementFeeAmount">
      <template #body="{data}">
         {{ formatAmount(data.managementFeeAmount) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="globalCompanyCost">
      <template #body="{data}">
         {{ formatAmount(data.globalCompanyCost) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="ndfRefundedAmount">
      <template #body="{data}">
         {{ formatAmount(data.ndfRefundedAmount) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="ndfToRefundAmount" body-style="color: #DD901DFF">
      <template #body="{data}">
         {{ formatAmount(data.ndfToRefundAmount) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="reserve">
      <template #body="{data}">
         {{ formatAmount(data.reserve) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="regularisation">
      <template #body="{data}">
         {{ formatAmount(data.regularisation) }}
      </template>
    </Column>
    <Column bodyClass="text-right font-bold" field="treasury" body-style="color: #6D8E79FF">
      <template #body="{data}">
        {{ formatAmount(data.treasury) }}
      </template>
    </Column>
    <Column bodyClass="text-right font-bold" field="waitingTreasury" body-style="color: #DD901DFF">
      <template #body="{data}">
        {{ formatAmount(data.waitingTreasury) }}
      </template>
    </Column>
    <Column bodyClass="text-right font-bold" field="pot" body-style="color: #4C63B5FF">
      <template #body="{data}">
        {{ formatAmount(data.pot) }}
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="TOTAL" :colspan="2" footer-class="text-xl text-black-alpha-90 font-bold"/>
        <Column :footer="total.invoicedHtTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.waitingToPaidHTTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.managementFeeAverageTotal"/>
        <Column :footer="total.managementFeeAmountTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.globalCompanyCostTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.ndfAmountRefundedTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.ndfAmountWaitingTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.reservedTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.regularisationTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.treasuryTotal" style="background-color: #6D8E79FF" footer-class="text-xl font-bold text-right text-white"/>
        <Column :footer="total.waitingTreasuryTotal" style="background-color: #DD901DFF" footer-class="text-xl font-bold text-right text-white"/>
        <Column :footer="total.potTotal" style="background-color: #4C63B5FF" footer-class="text-xl font-bold text-right text-white"/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>

<script>
import { getEuroFormat } from "@/utils/Money";

export default {
  name: "GeneralSynthesis",
  props: {
    summaries: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  computed: {
    total () {
      const totals = {
        waitingToPaidHTTotal: 0,
        invoicedHtTotal: 0,
        paidHTTotal: 0,
        managementFeeAverageTotal: '',
        managementFeeAmountTotal: 0,
        globalCompanyCostTotal: 0,
        ndfAmountWaitingTotal: 0,
        ndfAmountRefundedTotal: 0,
        reservedTotal: 0,
        regularisationTotal: 0,
        treasuryTotal: 0,
        waitingTreasuryTotal: 0,
        potTotal: 0,
      }

      this.summaries.forEach((salary, index) => {
          totals.waitingToPaidHTTotal += salary.waitingToPaidHt
          totals.invoicedHtTotal += salary.invoicedHt
          totals.paidHTTotal += salary.paidHt
          totals.managementFeeAmountTotal += salary.managementFeeAmount
          totals.globalCompanyCostTotal += salary.globalCompanyCost
          totals.ndfAmountWaitingTotal += salary.ndfToRefundAmount
          totals.ndfAmountRefundedTotal += salary.ndfRefundedAmount
          totals.reservedTotal += salary.reserve
          totals.regularisationTotal += salary.regularisation
          totals.treasuryTotal += salary.treasury
          totals.waitingTreasuryTotal += salary.waitingTreasury
          totals.potTotal += salary.pot

          if (index === this.summaries.length - 1) {
            totals.waitingToPaidHTTotal = getEuroFormat(totals.waitingToPaidHTTotal)
            totals.invoicedHtTotal = getEuroFormat(totals.invoicedHtTotal)
            totals.paidHTTotal = getEuroFormat(totals.paidHTTotal)
            totals.managementFeeAmountTotal = getEuroFormat(totals.managementFeeAmountTotal)
            totals.globalCompanyCostTotal = getEuroFormat(totals.globalCompanyCostTotal)
            totals.ndfAmountWaitingTotal = getEuroFormat(totals.ndfAmountWaitingTotal)
            totals.ndfAmountRefundedTotal = getEuroFormat(totals.ndfAmountRefundedTotal)
            totals.reservedTotal = getEuroFormat(totals.reservedTotal)
            totals.regularisationTotal = getEuroFormat(totals.regularisationTotal)
            totals.treasuryTotal = getEuroFormat(totals.treasuryTotal)
            totals.waitingTreasuryTotal = getEuroFormat(totals.waitingTreasuryTotal)
            totals.potTotal = getEuroFormat(totals.potTotal)
          }
      })

      return totals
    }
  },
  methods: {
    getEuroFormat,
    formatAmount (amount) {
      return amount ? getEuroFormat(amount) : ''
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/table.scss';

</style>
