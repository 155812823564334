<template>
  <DataTable ref="dt" :value="invoices" scrollable class="p-datatable-sm"
             row-hover :loading="loading" scroll-height="400px">
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <ColumnGroup type="header">
      <Row>
        <Column field="year" header="Année" :rowspan="2"/>
        <Column field="month" header="Mois" :rowspan="2"/>
        <Column field="client" header="Client" :rowspan="2"/>
        <Column field="jobTitle" header="Description de la mission" :rowspan="2"/>
        <Column field="paidHt" header="Réglé" :rowspan="2" class="column-text-right"/>
        <Column field="waitingToPaidHt" header="En attente" :rowspan="2" class="column-text-right"/>
        <Column header="Taux de gestion moyen" :colspan="2" header-class="column-text-center"/>
      </Row>
      <Row>
        <Column field="managementFeeAverage" header="%" header-class="column-text-right"/>
        <Column field="managementFeeAmount" header="Montant(€)" header-class="column-text-right"/>
      </Row>
    </ColumnGroup>
    <Column field="year" header="Année"/>
    <Column field="month" header="Mois">
      <template #body="{data}">
        <span> {{ mois[data.month - 1] }}</span>
      </template>
    </Column>
    <Column field="client"/>
    <Column field="jobTitle"/>
    <Column field="paidHt" body-class="text-right" body-style="color: #6D8E79FF">
      <template #body="{data}">
        {{ formatAmount(data.paidHt) }}
      </template>
    </Column>
    <Column body-class="text-right font-bold" field="waitingToPaidHt" body-style="color: #DD901DFF">
      <template #body="{data}">
        {{ formatAmount(data.waitingToPaidHt) }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="managementFeeAverage">
      <template #body="{data}">
        {{ data.managementFeeAverage ? `${data.managementFeeAverage.toFixed(1)} %` : '' }}
      </template>
    </Column>
    <Column bodyClass="text-right" field="managementFeeAmount">
      <template #body="{data}">
        {{ formatAmount(data.managementFeeAmount) }}
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="TOTAL" :colspan="4" footer-class="text-xl text-black-alpha-90 font-bold"/>
        <Column :footer="total.paidHtTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.waitingToPaidHtTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column footer=""/>
        <Column :footer="total.managementFeeAmountTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "InvoicesSynthesis",
  props: {
    invoices: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  computed: {
    total () {
      const totals = {
        paidHtTotal: 0,
        waitingToPaidHtTotal: 0,
        managementFeeAmountTotal: 0
      }

      this.invoices.forEach((invoice, index) => {
        totals.paidHtTotal += invoice.paidHt
        totals.waitingToPaidHtTotal += invoice.waitingToPaidHt
        totals.managementFeeAmountTotal += invoice.managementFeeAmount

        if (index === this.invoices.length - 1) {
          totals.paidHtTotal = getEuroFormat(totals.paidHtTotal)
          totals.waitingToPaidHtTotal = getEuroFormat(totals.waitingToPaidHtTotal)
          totals.managementFeeAmountTotal = getEuroFormat(totals.managementFeeAmountTotal)
        }
      })

      return totals
  }
  },
  methods: {
    getEuroFormat,
    formatAmount (amount) {
      return amount ? getEuroFormat(amount) : ''
    }
  }
}
</script>

<style scoped>

</style>
