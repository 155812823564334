<template>
  <DataTable ref="dt" :value="expenses" scrollable class="p-datatable-sm" row-hover
             :loading="loading" scroll-height="400px">
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <Column field="year" header="Année"/>
    <Column field="month" header="Mois">
      <template #body="{data}">
        <span> {{ mois[data.month - 1] }}</span>
      </template>
    </Column>
    <Column field="category" header="Nature"/>
    <Column field="amount" header="Montant" header-class="column-text-right" body-class="text-right">
      <template #body="{data}">
        <span> {{ data.amount ? getEuroFormat(data.amount) : '' }}</span>
      </template>
    </Column>
    <Column field="reimbursed" header="Remboursement" body-class="text-center" header-class="column-text-center">
      <template #body="{data}">
        <Tag rounded :severity="data.reimbursed ? 'success' : 'warning'" :value="data.reimbursed ? 'Remboursé' : 'Non remboursé'"/>
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="TOTAL" :colspan="3" footer-class="text-xl text-black-alpha-90 font-bold"/>
        <Column :footer="total.ndfAmountTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column footer=""/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "ExpensesSynthesis",
  props: {
    expenses: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  computed: {
    total () {
      const totals = {
        ndfAmountTotal: 0
      }

      this.expenses.forEach((expense, index) => {
        totals.ndfAmountTotal += expense.amount

        if (index === this.expenses.length - 1) {
          totals.ndfAmountTotal = getEuroFormat(totals.ndfAmountTotal)
        }
      })

      return totals
    }
  },
  methods: {
    getEuroFormat
  }
}
</script>

<style lang="scss">
@import '@/assets/table.scss';

</style>
