<template>
  <Dialog
      v-model:visible="dialogActive"
      class="dialog-file-preview"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :draggable="false"
      :close-on-escape="false"
      modal
      :pt="{
        header: { class: 'align-items-start text-center' },
      }"
  >
    <template #header>
      <div style="width: 100%; text-align: center;">
        <h3>{{ $t('syntheseNDF') }}</h3>
      </div>
    </template>
    <template #closeicon>
      <Button icon="pi pi-times" class="p-dialog-header-icon p-dialog-header-close p-link mr-0" @click="closeDialog"/>
    </template>
    <div class="flex flex-column gap-2">
      <CalendarRange :model-value="selectedDates" @update:model-value="getData" class="m-auto"/>
      <expenses-synthesis :expenses="expensesIndependent" :loading="loading"/>
    </div>
  </Dialog>
</template>

<script>
import ExpensesSynthesis from "@/components/Dashboard/ExpensesSynthesis.vue";
import CalendarRange from "@/components/CalendarRange.vue";

export default {
  name: "DialogNDFDetails",
  components: {CalendarRange, ExpensesSynthesis},
  emits: ['closeDialog'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    independent: {
      type: Object,
      required: true
    },
    range: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false,
      selectedDates: null,
      loading: false,
      expensesIndependent: []
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.selectedDates = this.range
        this.getData(this.range)
      }
      this.dialogActive = value
    }
  },
  methods: {
    async getData (range) {
      this.loading = true
      this.expensesIndependent = []
      this.expensesIndependent = await this.$store.dispatch('independent/getExpensesSynthesis', {
        id: this.independent.id,
        range
      })
      this.loading = false
    },
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>
