<template>
  <DataTable ref="dt" :value="salaries" scrollable class="p-datatable-sm"
             dataKey="id" row-hover responsiveLayout="scroll" :loading="loading">
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <ColumnGroup type="header">
      <Row>
        <Column field="year" header="Année" :rowspan="2"/>
        <Column field="month" header="Mois" :rowspan="2"/>
        <Column field="globalCompanyCost" header="Coût global" :rowspan="2" class="column-text-right"/>
        <Column field="brutSalary" header="Salaire brut" :rowspan="2" class="column-text-right"/>
        <Column field="employerCharge" header="Cotisations patronales" :rowspan="2" class="column-text-right"/>
        <Column field="salaryExpense" header="Cotisations sociales" :rowspan="2" class="column-text-right"/>
        <Column field="taxableRevenue" header="Revenus imposables" :rowspan="2" class="column-text-right"/>
        <Column field="pas" header="Prélèvement à la source" :rowspan="2" class="column-text-right"/>
        <Column field="reserve" header="Réserves bloquées" :rowspan="2" class="column-text-right"/>
      </Row>
    </ColumnGroup>
    <Column field="year" header="Année"/>
    <Column field="month" header="Mois">
      <template #body="{data}">
        <span> {{ mois[data.month - 1] }}</span>
      </template>
    </Column>
    <Column field="globalCompanyCost" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.globalCompanyCost) }}
      </template>
    </Column>
    <Column field="brutSalary" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.brutSalary) }}
      </template>
    </Column>
    <Column field="employerCharge" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.employerCharge) }}
      </template>
    </Column>
    <Column field="salaryExpense" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.salaryExpense) }}
      </template>
    </Column>
    <Column field="taxableRevenue" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.taxableRevenue) }}
      </template>
    </Column>
    <Column field="pas" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.pas) }}
      </template>
    </Column>
    <Column field="reserve" body-class="text-right">
      <template #body="{data}">
        {{ formatAmount(data.reserve) }}
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="TOTAL" :colspan="2" footer-class="text-xl text-black-alpha-90 font-bold"/>
        <Column :footer="total.globalCompanyCostTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.brutSalaryTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.employerChargeTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.salaryExpenseTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.taxableRevenueTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.pasTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column :footer="total.reserveTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
      </Row>
    </ColumnGroup>
  </DataTable>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "SalariesSynthesis",
  props: {
    salaries: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  computed: {
    total () {
      const totals = {
        globalCompanyCostTotal: 0,
        brutSalaryTotal: 0,
        employerChargeTotal: 0,
        salaryExpenseTotal: 0,
        taxableRevenueTotal: 0,
        pasTotal: 0,
        reserveTotal: 0
      }

      this.salaries.forEach((salary, index) => {
        totals.globalCompanyCostTotal += salary.globalCompanyCost
        totals.brutSalaryTotal += salary.brutSalary
        totals.employerChargeTotal += salary.employerCharge
        totals.salaryExpenseTotal += salary.salaryExpense
        totals.taxableRevenueTotal += salary.taxableRevenue
        totals.pasTotal += salary.pas
        totals.reserveTotal += salary.reserve

        if (index === this.salaries.length - 1) {
          totals.globalCompanyCostTotal = getEuroFormat(totals.globalCompanyCostTotal)
          totals.brutSalaryTotal = getEuroFormat(totals.brutSalaryTotal)
          totals.employerChargeTotal = getEuroFormat(totals.employerChargeTotal)
          totals.salaryExpenseTotal = getEuroFormat(totals.salaryExpenseTotal)
          totals.taxableRevenueTotal = getEuroFormat(totals.taxableRevenueTotal)
          totals.pasTotal = getEuroFormat(totals.pasTotal)
          totals.reserveTotal = getEuroFormat(totals.reserveTotal)
        }
      })

      return totals
    }
  },
  methods: {
    getEuroFormat,
    formatAmount (amount) {
      return amount ? getEuroFormat(amount) : ''
    }
  }
}
</script>

<style scoped>

</style>
