<template>
  <DataTable ref="dt" :value="summaries" scrollable class="p-datatable-sm" selection-mode="single" :loading="loading"
             scroll-height="400px" @row-click="openNdfDetails">
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <Column field="year" header="Année"/>
    <Column field="month" header="Mois">
      <template #body="{data}">
        <span> {{ mois[data.month - 1] }}</span>
      </template>
    </Column>
    <Column field="ndfRefundedAmount" header="Montant" header-class="column-text-right" body-class="text-right">
      <template #body="{data}">
        <span> {{ data.ndfRefundedAmount ? getEuroFormat(data.ndfRefundedAmount) : (data.ndfToRefundAmount ? getEuroFormat(data.ndfRefundedAmount) : getEuroFormat(0)) }}</span>
      </template>
    </Column>
    <Column field="ndfToRefundAmount" header="Remboursement" body-class="text-center" header-class="column-text-center">
      <template #body="{data}">
<!--          :style="{'background-color': data.ndfToRefundAmount ? '#DD901DFF' : '#6D8E79FF', color: 'white'}"-->
<!--          <Chip :label="data.ndfToRefundAmount ? 'Non remboursé' : 'Remboursé'" :style="{'background-color': data.ndfToRefundAmount ? '#DD901DFF' : '#6D8E79FF', color: 'white'}"/>-->
        <Tag rounded :severity="data.ndfToRefundAmount ? 'warning' : 'success'" :value="data.ndfToRefundAmount ? 'Non remboursé' : 'Remboursé'"/>
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="TOTAL" :colspan="2" footer-class="text-xl text-black-alpha-90 font-bold"/>
        <Column :footer="total.ndfAmountTotal" footer-class="text-xl text-black-alpha-90 font-bold text-right"/>
        <Column footer="" :colspan="2"/>
      </Row>
    </ColumnGroup>
  </DataTable>
  <DialogNDFDetails :range="ndfDetailsRange" :independent="independent" :is-active="activeNdfDetailsDialog" @close-dialog="closeNdfDetailsDialog"/>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import DialogNDFDetails from "@/components/Dashboard/DialogNDFDetails.vue";
import {setDateWithoutHours} from "@/utils/Date";

export default {
  name: "NDFSynthesis",
  components: {DialogNDFDetails},
  props: {
    summaries: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    independent: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      activeNdfDetailsDialog: false,
      ndfDetailsRange: [null, null]
    }
  },
  computed: {
    total () {
      const totals = {
        ndfAmountTotal: 0
      }

      this.summaries.forEach((summary, index) => {
        totals.ndfAmountTotal += summary.ndfRefundedAmount ? summary.ndfRefundedAmount : summary.ndfToRefundAmount ? summary.ndfRefundedAmount : 0

        if (index === this.summaries.length - 1) {
          totals.ndfAmountTotal = getEuroFormat(totals.ndfAmountTotal)
        }
      })

      return totals
    }
  },
  methods: {
    getEuroFormat,
    closeNdfDetailsDialog () {
      this.activeNdfDetailsDialog = false
      this.ndfDetailsRange = null
    },
    openNdfDetails (ndf) {
      const startRangeDate = new Date(setDateWithoutHours(new Date(ndf.data.year, ndf.data.month - 1)))
      const endRangeDate = new Date(setDateWithoutHours(new Date(ndf.data.year, ndf.data.month, 0)))
      this.ndfDetailsRange = [startRangeDate, endRangeDate]
      this.activeNdfDetailsDialog = true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/table.scss';

</style>
